import ModalCore from "components/common/modals/ModalCore";
import Image from "next/image";
import { X } from "phosphor-react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useState } from "react";

// EnlargableImage extends Image for maximum compatibility (and easy refactoring of existing uses of Image to EnlargableImage)
// However, we do need width and height to be numbers (in pixels) and can't support strings like "20rem" in order to do our math
interface EnlargableImageProps extends React.ComponentProps<typeof Image> {
    width: number;
    height: number;
}

export default function EnlargableImage(props: EnlargableImageProps) {
    const { width, height, src, alt } = props;
    const [isEnlarged, setIsEnlarged] = useState(false);

    // We can't solve for sizing the modal with pure CSS without leaving a ton of whitespace to one side,
    // so we'll use JS to calculate the correct size

    // On Desktop only (width > 1024px), we add a 4 rem padding on the modal container which shows as a white boarder around the image
    const modalXYPadding = window.innerWidth > 1024 ? 16 * 2 : 0;
    // ModalCore always has `my-4` applied to the container so we need to account for that margin
    const modalYMargin = 16 * 2;

    // Math
    const modalImageMaxHeight = window.innerHeight - modalYMargin - modalXYPadding;
    const modalImageMaxWidth = window.innerWidth - modalXYPadding;
    const modalImageAspectRatio = width / height;
    const modalImageMaxAspectRatio = modalImageMaxWidth / modalImageMaxHeight;
    const isModalHeightConstrained = modalImageAspectRatio < modalImageMaxAspectRatio;
    const modalImageHeight = isModalHeightConstrained
        ? modalImageMaxHeight
        : modalImageMaxWidth / modalImageAspectRatio;
    const modalImageWidth = isModalHeightConstrained
        ? modalImageMaxHeight * modalImageAspectRatio
        : modalImageMaxWidth;

    return (
        <TransformWrapper centerOnInit>
            {({ resetTransform }) => {
                const close = () => {
                    setIsEnlarged(false);
                    resetTransform();
                };

                return (
                    <>
                        {/* react-zoom-pan-pinch throws an error when there are no TransformComponent children, and the "real" TransformComponent we use for our image is not immediately mounted sometimes causing an error. This dummy TransformComponent solves it. */}
                        <TransformComponent>
                            <div />
                        </TransformComponent>
                        <div className="cursor-pointer" onClick={() => setIsEnlarged(true)}>
                            {/* alt is a required prop and is being passed in, but eslint doesn't seem to be able to detect that */}
                            {/* eslint-disable-next-line jsx-a11y/alt-text */}
                            <Image {...props} />
                        </div>
                        <ModalCore
                            isOpen={isEnlarged}
                            setIsOpen={(newValue: boolean) => {
                                if (!newValue) close();
                            }}
                            containerClassName="relative lg:bg-white lg:border lg:border-lightGreyBorder lg:shadow-card lg:rounded-lg lg:p-4"
                            noPadding
                        >
                            <div>
                                <div
                                    className="absolute z-20 p-2 lg:p-6 right-0 top-0 bg-white bg-opacity-20"
                                    onClick={() => close()}
                                >
                                    <X size={24} />
                                </div>
                                <TransformComponent>
                                    <div className="w-screen h-[calc(100vh-64px)] lg:w-full lg:h-full flex items-center justify-center">
                                        <Image
                                            src={src}
                                            alt={alt}
                                            width={modalImageWidth}
                                            height={modalImageHeight}
                                        />
                                    </div>
                                </TransformComponent>
                                <p className="block lg:hidden absolute z-20 p-2 left-0 bottom-10 bg-white bg-opacity-20">
                                    Pinch to Zoom
                                </p>
                            </div>
                        </ModalCore>
                    </>
                );
            }}
        </TransformWrapper>
    );
}
